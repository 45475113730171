:root {
  --blue: #007BFF;
  --breakpoint-lg: 992px;
  --breakpoint-md: 768px;
  --breakpoint-sm: 576px;
  --breakpoint-xl: 1200px;
  --breakpoint-xs: 0;
  --cyan: #17A2B8;
  --danger: #DC3545;
  --dark: #343A40;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --gray: #6C757D;
  --gray-dark: #343A40;
  --green: #28A745;
  --indigo: #6610F2;
  --info: #17A2B8;
  --light: #F8F9FA;
  --orange: #FD7E14;
  --pink: #E83E8C;
  --primary: #007BFF;
  --purple: #6F42C1;
  --red: #DC3545;
  --secondary: #6C757D;
  --success: #28A745;
  --teal: #20C997;
  --warning: #FFC107;
  --white: #FFF;
  --yellow: #FFC107; }

* {
  box-sizing: border-box; }
  *::before, *::after {
    box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block; }

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  text-align: left;
  color: #212529;
  background-color: #FFF; }

[tabindex='-1']:focus {
  outline: 0 !important; }

hr {
  overflow: visible;
  box-sizing: content-box;
  height: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title], abbr[data-original-title] {
  cursor: help;
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  -webkit-text-decoration: underline dotted; }

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: 0.5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

a {
  text-decoration: none;
  color: #007BFF;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    text-decoration: underline;
    color: #0056B3; }
  a:not([href]):not([tabindex]) {
    text-decoration: none;
    color: inherit; }
    a:not([href]):not([tabindex]):hover {
      text-decoration: none;
      color: inherit; }
    a:not([href]):not([tabindex]):focus {
      text-decoration: none;
      color: inherit;
      outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  font-size: 1em; }

pre {
  overflow: auto;
  margin-top: 0;
  margin-bottom: 1rem;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  caption-side: bottom;
  text-align: left;
  color: #6C757D; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type='radio'], input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0; }

input[type='date'], input[type='time'], input[type='datetime-local'], input[type='month'] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  font-size: 1.5rem;
  line-height: inherit;
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5rem;
  padding: 0;
  white-space: normal;
  color: inherit; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button, [type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none; }
  [type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  color: inherit; }

h1,
.h1 {
  font-size: 2.5rem; }

h2,
.h2 {
  font-size: 2rem; }

h3,
.h3 {
  font-size: 1.75rem; }

h4,
.h4 {
  font-size: 1.5rem; }

h5,
.h5 {
  font-size: 1.25rem; }

h6,
.h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #FCF8E3; }

.list-unstyled,
.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  font-size: 1.25rem;
  margin-bottom: 1rem; }

.blockquote-footer {
  font-size: 80%;
  display: block;
  color: #6C757D; }
  .blockquote-footer::before {
    content: '\2014 \00A0'; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  max-width: 100%;
  height: auto;
  padding: 0.25rem;
  border: 1px solid #DEE2E6;
  border-radius: 0.25rem;
  background-color: #FFF; }

.figure {
  display: inline-block; }

.figure-img {
  line-height: 1;
  margin-bottom: 0.5rem; }

.figure-caption {
  font-size: 90%;
  color: #6C757D; }

code {
  font-size: 87.5%;
  word-break: break-word;
  color: #E83E8C; }

a > code {
  color: inherit; }

kbd {
  font-size: 87.5%;
  padding: 0.2rem 0.4rem;
  color: #FFF;
  border-radius: 0.2rem;
  background-color: #212529; }
  kbd kbd {
    font-size: 100%;
    font-weight: 700;
    padding: 0; }

pre {
  font-size: 87.5%;
  display: block;
  color: #212529; }
  pre code {
    font-size: inherit;
    word-break: normal;
    color: inherit; }

.pre-scrollable {
  overflow-y: scroll;
  max-height: 340px; }

.container,
.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

@media screen and (min-width: 1200px) and (max-width: 1440px) {
  .container {
    max-width: 1030px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-right: 0;
    padding-left: 0; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  z-index: 0 !important;
  width: 100%;
  min-height: 0;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #DEE2E6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #DEE2E6; }
  .table tbody + tbody {
    border-top: 2px solid #DEE2E6; }
  .table .table {
    background-color: #FFF; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #DEE2E6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #DEE2E6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary {
  background-color: #B8DAFF; }
  .table-primary > th,
  .table-primary > td {
    background-color: #B8DAFF; }

.table-hover .table-primary:hover {
  background-color: #9FCDFF; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9FCDFF; }

.table-secondary {
  background-color: #D6D8DB; }
  .table-secondary > th,
  .table-secondary > td {
    background-color: #D6D8DB; }

.table-hover .table-secondary:hover {
  background-color: #C8CBCF; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #C8CBCF; }

.table-success {
  background-color: #C3E6CB; }
  .table-success > th,
  .table-success > td {
    background-color: #C3E6CB; }

.table-hover .table-success:hover {
  background-color: #B1DFBB; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #B1DFBB; }

.table-info {
  background-color: #BEE5EB; }
  .table-info > th,
  .table-info > td {
    background-color: #BEE5EB; }

.table-hover .table-info:hover {
  background-color: #ABDDE5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #ABDDE5; }

.table-warning {
  background-color: #FFEEBA; }
  .table-warning > th,
  .table-warning > td {
    background-color: #FFEEBA; }

.table-hover .table-warning:hover {
  background-color: #FFE8A1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #FFE8A1; }

.table-danger {
  background-color: #F5C6CB; }
  .table-danger > th,
  .table-danger > td {
    background-color: #F5C6CB; }

.table-hover .table-danger:hover {
  background-color: #F1B0B7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #F1B0B7; }

.table-light {
  background-color: #FDFDFE; }
  .table-light > th,
  .table-light > td {
    background-color: #FDFDFE; }

.table-hover .table-light:hover {
  background-color: #ECECF6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ECECF6; }

.table-dark {
  background-color: #C6C8CA; }
  .table-dark > th,
  .table-dark > td {
    background-color: #C6C8CA; }

.table-hover .table-dark:hover {
  background-color: #B9BBBE; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #B9BBBE; }

.table-active {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  border-color: #32383E;
  background-color: #212529; }

.table .thead-light th {
  color: #495057;
  border-color: #DEE2E6;
  background-color: #E9ECEF; }

.table-dark {
  color: #FFF;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383E; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  overflow-x: auto;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  font-size: 1rem;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #495057;
  border: 1px solid #CED4DA;
  border-radius: 0.25rem;
  background-color: #FFF;
  background-clip: padding-box; }
  .form-control::-ms-expand {
    border: 0;
    background-color: transparent; }
  .form-control:focus {
    color: #495057;
    border-color: #80BDFF;
    outline: 0;
    background-color: #FFF;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder, .form-control::placeholder {
    opacity: 1;
    color: #6C757D; }
  .form-control:disabled, .form-control[readonly] {
    opacity: 1;
    background-color: #E9ECEF; }

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  font-size: inherit;
  line-height: 1.5;
  margin-bottom: 0;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px); }

.col-form-label-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px); }

.col-form-label-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px); }

.form-control-plaintext {
  line-height: 1.5;
  display: block;
  width: 100%;
  margin-bottom: 0;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  color: #212529;
  border: solid transparent;
  border-width: 1px 0;
  background-color: transparent; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  font-size: 0.875rem;
  line-height: 1.5;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem; }

.form-control-lg {
  font-size: 1.25rem;
  line-height: 1.5;
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*='col-'] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6C757D; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  -ms-flex-align: center;
  margin-right: 0.75rem;
  padding-left: 0; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  font-size: 80%;
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  color: #28A745; }

.valid-tooltip {
  font-size: 0.875rem;
  line-height: 1.5;
  position: absolute;
  z-index: 5;
  top: 100%;
  display: none;
  max-width: 100%;
  margin-top: 0.1rem;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  border-radius: 0.25rem;
  background-color: rgba(40, 167, 69, 0.9); }

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28A745; }

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28A745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip {
  display: block; }

.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block; }

.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28A745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28A745; }

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71DD8A; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34CE57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28A745; }

.was-validated .custom-file-input:valid ~ .custom-file-label::after,
.custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  font-size: 80%;
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  color: #DC3545; }

.invalid-tooltip {
  font-size: 0.875rem;
  line-height: 1.5;
  position: absolute;
  z-index: 5;
  top: 100%;
  display: none;
  max-width: 100%;
  margin-top: 0.1rem;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  border-radius: 0.25rem;
  background-color: rgba(220, 53, 69, 0.9); }

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #DC3545; }

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block; }

.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block; }

.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #DC3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #EFA2A9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #E4606D; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label::after,
.custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  flex-flow: row wrap; }
  .form-inline .form-check {
    width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    flex: 0 0 auto;
    flex-flow: row wrap;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto; }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    align-items: center;
    -ms-flex-align: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .btn:hover {
    text-decoration: none; }
  .btn:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #FFF;
  border-color: #007BFF;
  background-color: #007BFF; }
  .btn-primary:hover {
    color: #FFF;
    border-color: #0062CC;
    background-color: #0069D9; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #FFF;
    border-color: #007BFF;
    background-color: #007BFF; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #005CBF;
    background-color: #0062CC; }

.show > .btn-primary.dropdown-toggle {
  color: #FFF;
  border-color: #005CBF;
  background-color: #0062CC; }

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #FFF;
  border-color: #6C757D;
  background-color: #6C757D; }
  .btn-secondary:hover {
    color: #FFF;
    border-color: #545B62;
    background-color: #5A6268; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #FFF;
    border-color: #6C757D;
    background-color: #6C757D; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #4E555B;
    background-color: #545B62; }

.show > .btn-secondary.dropdown-toggle {
  color: #FFF;
  border-color: #4E555B;
  background-color: #545B62; }

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-success {
  color: #FFF;
  border-color: #28A745;
  background-color: #28A745; }
  .btn-success:hover {
    color: #FFF;
    border-color: #1E7E34;
    background-color: #218838; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #FFF;
    border-color: #28A745;
    background-color: #28A745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #1C7430;
    background-color: #1E7E34; }

.show > .btn-success.dropdown-toggle {
  color: #FFF;
  border-color: #1C7430;
  background-color: #1E7E34; }

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #FFF;
  border-color: #17A2B8;
  background-color: #17A2B8; }
  .btn-info:hover {
    color: #FFF;
    border-color: #117A8B;
    background-color: #138496; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #FFF;
    border-color: #17A2B8;
    background-color: #17A2B8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #10707F;
    background-color: #117A8B; }

.show > .btn-info.dropdown-toggle {
  color: #FFF;
  border-color: #10707F;
  background-color: #117A8B; }

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  border-color: #FFC107;
  background-color: #FFC107; }
  .btn-warning:hover {
    color: #212529;
    border-color: #D39E00;
    background-color: #E0A800; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    border-color: #FFC107;
    background-color: #FFC107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active {
    color: #212529;
    border-color: #C69500;
    background-color: #D39E00; }

.show > .btn-warning.dropdown-toggle {
  color: #212529;
  border-color: #C69500;
  background-color: #D39E00; }

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #FFF;
  border-color: #DC3545;
  background-color: #DC3545; }
  .btn-danger:hover {
    color: #FFF;
    border-color: #BD2130;
    background-color: #C82333; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #FFF;
    border-color: #DC3545;
    background-color: #DC3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #B21F2D;
    background-color: #BD2130; }

.show > .btn-danger.dropdown-toggle {
  color: #FFF;
  border-color: #B21F2D;
  background-color: #BD2130; }

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  border-color: #F8F9FA;
  background-color: #F8F9FA; }
  .btn-light:hover {
    color: #212529;
    border-color: #DAE0E5;
    background-color: #E2E6EA; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    border-color: #F8F9FA;
    background-color: #F8F9FA; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active {
    color: #212529;
    border-color: #D3D9DF;
    background-color: #DAE0E5; }

.show > .btn-light.dropdown-toggle {
  color: #212529;
  border-color: #D3D9DF;
  background-color: #DAE0E5; }

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #FFF;
  border-color: #343A40;
  background-color: #343A40; }
  .btn-dark:hover {
    color: #FFF;
    border-color: #1D2124;
    background-color: #23272B; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #FFF;
    border-color: #343A40;
    background-color: #343A40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #171A1D;
    background-color: #1D2124; }

.show > .btn-dark.dropdown-toggle {
  color: #FFF;
  border-color: #171A1D;
  background-color: #1D2124; }

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007BFF;
  border-color: #007BFF;
  background-color: transparent;
  background-image: none; }
  .btn-outline-primary:hover {
    color: #FFF;
    border-color: #007BFF;
    background-color: #007BFF; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007BFF;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #007BFF;
    background-color: #007BFF; }

.show > .btn-outline-primary.dropdown-toggle {
  color: #FFF;
  border-color: #007BFF;
  background-color: #007BFF; }

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6C757D;
  border-color: #6C757D;
  background-color: transparent;
  background-image: none; }
  .btn-outline-secondary:hover {
    color: #FFF;
    border-color: #6C757D;
    background-color: #6C757D; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6C757D;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #6C757D;
    background-color: #6C757D; }

.show > .btn-outline-secondary.dropdown-toggle {
  color: #FFF;
  border-color: #6C757D;
  background-color: #6C757D; }

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28A745;
  border-color: #28A745;
  background-color: transparent;
  background-image: none; }
  .btn-outline-success:hover {
    color: #FFF;
    border-color: #28A745;
    background-color: #28A745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28A745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #28A745;
    background-color: #28A745; }

.show > .btn-outline-success.dropdown-toggle {
  color: #FFF;
  border-color: #28A745;
  background-color: #28A745; }

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17A2B8;
  border-color: #17A2B8;
  background-color: transparent;
  background-image: none; }
  .btn-outline-info:hover {
    color: #FFF;
    border-color: #17A2B8;
    background-color: #17A2B8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17A2B8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #17A2B8;
    background-color: #17A2B8; }

.show > .btn-outline-info.dropdown-toggle {
  color: #FFF;
  border-color: #17A2B8;
  background-color: #17A2B8; }

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #FFC107;
  border-color: #FFC107;
  background-color: transparent;
  background-image: none; }
  .btn-outline-warning:hover {
    color: #212529;
    border-color: #FFC107;
    background-color: #FFC107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FFC107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active {
    color: #212529;
    border-color: #FFC107;
    background-color: #FFC107; }

.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  border-color: #FFC107;
  background-color: #FFC107; }

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #DC3545;
  border-color: #DC3545;
  background-color: transparent;
  background-image: none; }
  .btn-outline-danger:hover {
    color: #FFF;
    border-color: #DC3545;
    background-color: #DC3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DC3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #DC3545;
    background-color: #DC3545; }

.show > .btn-outline-danger.dropdown-toggle {
  color: #FFF;
  border-color: #DC3545;
  background-color: #DC3545; }

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #F8F9FA;
  border-color: #F8F9FA;
  background-color: transparent;
  background-image: none; }
  .btn-outline-light:hover {
    color: #212529;
    border-color: #F8F9FA;
    background-color: #F8F9FA; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F8F9FA;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active {
    color: #212529;
    border-color: #F8F9FA;
    background-color: #F8F9FA; }

.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  border-color: #F8F9FA;
  background-color: #F8F9FA; }

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343A40;
  border-color: #343A40;
  background-color: transparent;
  background-image: none; }
  .btn-outline-dark:hover {
    color: #FFF;
    border-color: #343A40;
    background-color: #343A40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343A40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active {
    color: #FFF;
    border-color: #343A40;
    background-color: #343A40; }

.show > .btn-outline-dark.dropdown-toggle {
  color: #FFF;
  border-color: #343A40;
  background-color: #343A40; }

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007BFF;
  background-color: transparent; }
  .btn-link:hover {
    text-decoration: underline;
    color: #0056B3;
    border-color: transparent;
    background-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    pointer-events: none;
    color: #6C757D; }

.btn-lg,
.btn-group-lg > .btn {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.5rem 1rem;
  border-radius: 0.3rem; }

.btn-sm,
.btn-group-sm > .btn {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type='submit'].btn-block, input[type='reset'].btn-block, input[type='button'].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  .fade:not(.show) {
    opacity: 0; }

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0.35s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  content: '';
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  font-size: 1rem;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  padding: 0.5rem 0;
  list-style: none;
  text-align: left;
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  background-color: #FFF;
  background-clip: padding-box; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  content: '';
  vertical-align: 0.255em;
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  content: '';
  vertical-align: 0.255em;
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  display: none;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  content: '';
  vertical-align: 0.255em; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  content: '';
  vertical-align: 0.255em;
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^='top'], .dropdown-menu[x-placement^='right'], .dropdown-menu[x-placement^='bottom'], .dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  overflow: hidden;
  height: 0;
  margin: 0.5rem 0;
  border-top: 1px solid #E9ECEF; }

.dropdown-item {
  font-weight: 400;
  display: block;
  clear: both;
  width: 100%;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  color: #212529;
  border: 0;
  background-color: transparent; }
  .dropdown-item:hover, .dropdown-item:focus {
    text-decoration: none;
    color: #16181B;
    background-color: #F8F9FA; }
  .dropdown-item.active, .dropdown-item:active {
    text-decoration: none;
    color: #FFF;
    background-color: #007BFF; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6C757D;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  font-size: 0.875rem;
  display: block;
  margin-bottom: 0;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  color: #6C757D; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 0 1 auto; }

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1; }

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active {
  z-index: 1; }

.btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1; }

.btn-group .btn + .btn,
.btn-group .btn + .btn-group {
  margin-left: -1px; }

.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
  margin-left: -1px; }

.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group {
  margin-left: -1px; }

.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0; }

.dropleft .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  align-items: flex-start;
  -ms-flex-align: start;
  flex-direction: column;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type='radio'], .btn-group-toggle > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.btn-group-toggle > .btn-group > .btn input[type='radio'], .btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  -ms-flex-align: stretch;
  flex-wrap: wrap;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file {
    margin-left: -1px; }
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file {
    margin-left: -1px; }
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
    .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
      z-index: 3; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center;
    -ms-flex-align: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
      .input-group > .custom-file:not(:last-child) .custom-file-label::after {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2; }

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text {
  margin-left: -1px; }

.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn {
  margin-left: -1px; }

.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text {
  margin-left: -1px; }

.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap;
  color: #495057;
  border: 1px solid #CED4DA;
  border-radius: 0.25rem;
  background-color: #E9ECEF; }
  .input-group-text input[type='radio'], .input-group-text input[type='checkbox'] {
    margin-top: 0; }

.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  font-size: 1.25rem;
  line-height: 1.5;
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  font-size: 0.875rem;
  line-height: 1.5;
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  border-radius: 0.2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    background-color: #007BFF; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #B3D7FF; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6C757D; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #E9ECEF; }

.custom-control-label {
  position: relative;
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
    background-color: #DEE2E6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: '';
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007BFF; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007BFF; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007BFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  line-height: 1.5;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  vertical-align: middle;
  color: #495057;
  border: 1px solid #CED4DA;
  border-radius: 0.25rem;
  background: #FFF url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-select:focus {
    border-color: #80BDFF;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #FFF; }
  .custom-select[multiple], .custom-select[size]:not([size='1']) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6C757D;
    background-color: #E9ECEF; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  font-size: 75%;
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.custom-select-lg {
  font-size: 125%;
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80BDFF;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #80BDFF; }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #E9ECEF; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: 'Browse'; }

.custom-file-label {
  line-height: 1.5;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  color: #495057;
  border: 1px solid #CED4DA;
  border-radius: 0.25rem;
  background-color: #FFF; }
  .custom-file-label::after {
    line-height: 1.5;
    position: absolute;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 2.25rem;
    padding: 0.375rem 0.75rem;
    content: 'Browse';
    color: #495057;
    border-left: 1px solid #CED4DA;
    border-radius: 0 0.25rem 0.25rem 0;
    background-color: #E9ECEF; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb, .custom-range:focus::-moz-range-thumb, .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    border-radius: 1rem;
    background-color: #007BFF;
    -webkit-appearance: none;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #B3D7FF; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    cursor: pointer;
    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #DEE2E6; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    border-radius: 1rem;
    background-color: #007BFF;
    -moz-appearance: none;
    appearance: none; }
    .custom-range::-moz-range-thumb:active {
      background-color: #B3D7FF; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    cursor: pointer;
    color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    background-color: #DEE2E6; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 0;
    border-radius: 1rem;
    background-color: #007BFF;
    appearance: none; }
    .custom-range::-ms-thumb:active {
      background-color: #B3D7FF; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    cursor: pointer;
    color: transparent;
    border-width: 0.5rem;
    border-color: transparent;
    background-color: transparent; }
  .custom-range::-ms-fill-lower {
    border-radius: 1rem;
    background-color: #DEE2E6; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    border-radius: 1rem;
    background-color: #DEE2E6; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none; } }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6C757D; }

.nav-tabs {
  border-bottom: 1px solid #DEE2E6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #E9ECEF #E9ECEF #DEE2E6; }
    .nav-tabs .nav-link.disabled {
      color: #6C757D;
      border-color: transparent;
      background-color: transparent; }
    .nav-tabs .nav-link.active {
      color: #495057;
      border-color: #DEE2E6 #DEE2E6 #FFF;
      background-color: #FFF; }
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    border-color: #DEE2E6 #DEE2E6 #FFF;
    background-color: #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }
  .nav-pills .nav-link.active {
    color: #FFF;
    background-color: #007BFF; }

.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #007BFF; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    align-items: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    justify-content: space-between; }

.navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  display: inline-block;
  margin-right: 1rem;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  align-items: center;
  -ms-flex-align: center;
  flex-basis: 100%;
  flex-grow: 1; }

.navbar-toggler {
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  content: '';
  vertical-align: middle;
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link {
  color: #FFF; }

.navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #FFF;
  background-clip: border-box; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  margin-bottom: 0;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.03); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  background-color: rgba(0, 0, 0, 0.03); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:first-child .card-img-top,
        .card-group > .card:first-child .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:first-child .card-img-bottom,
        .card-group > .card:first-child .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:last-child .card-img-top,
        .card-group > .card:last-child .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:last-child .card-img-bottom,
        .card-group > .card:last-child .card-footer {
          border-bottom-left-radius: 0; }
      .card-group > .card:only-child {
        border-radius: 0.25rem; }
        .card-group > .card:only-child .card-img-top,
        .card-group > .card:only-child .card-header {
          border-top-left-radius: 0.25rem;
          border-top-right-radius: 0.25rem; }
        .card-group > .card:only-child .card-img-bottom,
        .card-group > .card:only-child .card-footer {
          border-bottom-right-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem; }
      .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
          border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -moz-column-count: 3;
    column-count: 3;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  list-style: none;
  border-radius: 0.25rem;
  background-color: #E9ECEF; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    content: '/';
    color: #6C757D; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
    text-decoration: none; }

.breadcrumb-item.active {
  color: #6C757D; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  line-height: 1.25;
  position: relative;
  display: block;
  margin-left: -1px;
  padding: 0.5rem 0.75rem;
  color: #007BFF;
  border: 1px solid #DEE2E6;
  background-color: #FFF; }
  .page-link:hover {
    z-index: 2;
    text-decoration: none;
    color: #0056B3;
    border-color: #DEE2E6;
    background-color: #E9ECEF; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  border-color: #007BFF;
  background-color: #007BFF; }

.page-item.disabled .page-link {
  cursor: auto;
  pointer-events: none;
  color: #6C757D;
  border-color: #DEE2E6;
  background-color: #FFF; }

.pagination-lg .page-link {
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.75rem 1.5rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  display: inline-block;
  padding: 0.25em 0.4em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFF;
  background-color: #007BFF; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #0062CC; }

.badge-secondary {
  color: #FFF;
  background-color: #6C757D; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #545B62; }

.badge-success {
  color: #FFF;
  background-color: #28A745; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #1E7E34; }

.badge-info {
  color: #FFF;
  background-color: #17A2B8; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #117A8B; }

.badge-warning {
  color: #212529;
  background-color: #FFC107; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    text-decoration: none;
    color: #212529;
    background-color: #D39E00; }

.badge-danger {
  color: #FFF;
  background-color: #DC3545; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #BD2130; }

.badge-light {
  color: #212529;
  background-color: #F8F9FA; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    text-decoration: none;
    color: #212529;
    background-color: #DAE0E5; }

.badge-dark {
  color: #FFF;
  background-color: #343A40; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    text-decoration: none;
    color: #FFF;
    background-color: #1D2124; }

.jumbotron {
  margin-bottom: 2rem;
  padding: 2rem 1rem;
  border-radius: 0.3rem;
  background-color: #E9ECEF; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  margin-bottom: 1rem;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  border-color: #B8DAFF;
  background-color: #CCE5FF; }
  .alert-primary hr {
    border-top-color: #9FCDFF; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383D41;
  border-color: #D6D8DB;
  background-color: #E2E3E5; }
  .alert-secondary hr {
    border-top-color: #C8CBCF; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  border-color: #C3E6CB;
  background-color: #D4EDDA; }
  .alert-success hr {
    border-top-color: #B1DFBB; }
  .alert-success .alert-link {
    color: #0B2E13; }

.alert-info {
  color: #0C5460;
  border-color: #BEE5EB;
  background-color: #D1ECF1; }
  .alert-info hr {
    border-top-color: #ABDDE5; }
  .alert-info .alert-link {
    color: #062C33; }

.alert-warning {
  color: #856404;
  border-color: #FFEEBA;
  background-color: #FFF3CD; }
  .alert-warning hr {
    border-top-color: #FFE8A1; }
  .alert-warning .alert-link {
    color: #533F03; }

.alert-danger {
  color: #721C24;
  border-color: #F5C6CB;
  background-color: #F8D7DA; }
  .alert-danger hr {
    border-top-color: #F1B0B7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  border-color: #FDFDFE;
  background-color: #FEFEFE; }
  .alert-light hr {
    border-top-color: #ECECF6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1B1E21;
  border-color: #C6C8CA;
  background-color: #D6D8D9; }
  .alert-dark hr {
    border-top-color: #B9BBBE; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  font-size: 0.75rem;
  display: flex;
  overflow: hidden;
  height: 1rem;
  border-radius: 0.25rem;
  background-color: #E9ECEF; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: width 0.6s ease;
  text-align: center;
  white-space: nowrap;
  color: #FFF;
  background-color: #007BFF; }

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start;
  -ms-flex-align: start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }

.list-group-item-action {
  width: 100%;
  text-align: inherit;
  color: #495057; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    text-decoration: none;
    color: #495057;
    background-color: #F8F9FA; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #E9ECEF; }

.list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #FFF; }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6C757D;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFF;
    border-color: #007BFF;
    background-color: #007BFF; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #B8DAFF; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9FCDFF; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    border-color: #004085;
    background-color: #004085; }

.list-group-item-secondary {
  color: #383D41;
  background-color: #D6D8DB; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383D41;
    background-color: #C8CBCF; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    border-color: #383D41;
    background-color: #383D41; }

.list-group-item-success {
  color: #155724;
  background-color: #C3E6CB; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #B1DFBB; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    border-color: #155724;
    background-color: #155724; }

.list-group-item-info {
  color: #0C5460;
  background-color: #BEE5EB; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0C5460;
    background-color: #ABDDE5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    border-color: #0C5460;
    background-color: #0C5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #FFEEBA; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #FFE8A1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    border-color: #856404;
    background-color: #856404; }

.list-group-item-danger {
  color: #721C24;
  background-color: #F5C6CB; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721C24;
    background-color: #F1B0B7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    border-color: #721C24;
    background-color: #721C24; }

.list-group-item-light {
  color: #818182;
  background-color: #FDFDFE; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ECECF6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    border-color: #818182;
    background-color: #818182; }

.list-group-item-dark {
  color: #1B1E21;
  background-color: #C6C8CA; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1B1E21;
    background-color: #B9BBBE; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    border-color: #1B1E21;
    background-color: #1B1E21; }

.close {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  float: right;
  opacity: 0.5;
  color: #000;
  text-shadow: 0 1px 0 #FFF; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }
    .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
      text-decoration: none;
      opacity: 0.75;
      color: #000; }

button.close {
  padding: 0;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%); }

.modal.show .modal-dialog {
  transform: translate(0, 0); }

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  min-height: calc(100% - (0.5rem * 2)); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - (0.5rem * 2));
    content: ''; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  background-color: #FFF;
  background-clip: padding-box; }

.modal-backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  -ms-flex-align: start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #E9ECEF;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    margin: -1rem -1rem -1rem auto;
    padding: 1rem; }

.modal-title {
  line-height: 1.5;
  margin-bottom: 0; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #E9ECEF; }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  overflow: scroll;
  width: 50px;
  height: 50px; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
    .modal-dialog-centered::before {
      height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;
  opacity: 0;
  text-shadow: none;
  line-break: auto; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: '';
      border-style: solid;
      border-color: transparent; }

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0; }

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000; }

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem; }

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000; }

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0; }

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000; }

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem; }

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
  color: #FFF;
  border-radius: 0.25rem;
  background-color: #000; }

.popover {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 0.875rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  position: absolute;
  z-index: 1060;
  top: 0;
  left: 0;
  display: block;
  max-width: 276px;
  text-align: left;
  text-align: start;
  white-space: normal;
  text-decoration: none;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  word-wrap: break-word;
  word-break: normal;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  background-color: #FFF;
  background-clip: padding-box;
  text-shadow: none;
  line-break: auto; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: '';
      border-style: solid;
      border-color: transparent; }

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem; }

.bs-popover-top .arrow,
.bs-popover-auto[x-placement^='top'] .arrow {
  bottom: calc((0.5rem + 1px) * -1); }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^='top'] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^='top'] .arrow::after {
  bottom: 1px;
  border-top-color: #FFF; }

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem; }

.bs-popover-right .arrow,
.bs-popover-auto[x-placement^='right'] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^='right'] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^='right'] .arrow::after {
  left: 1px;
  border-right-color: #FFF; }

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem; }

.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^='bottom'] .arrow {
  top: calc((0.5rem + 1px) * -1); }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^='bottom'] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^='bottom'] .arrow::after {
  top: 1px;
  border-bottom-color: #FFF; }

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #F7F7F7; }

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem; }

.bs-popover-left .arrow,
.bs-popover-auto[x-placement^='left'] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^='left'] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^='left'] .arrow::after {
  right: 1px;
  border-left-color: #FFF; }

.popover-header {
  font-size: 1rem;
  margin-bottom: 0;
  padding: 0.5rem 0.75rem;
  color: inherit;
  border-bottom: 1px solid #EBEBEB;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background-color: #F7F7F7; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  -ms-flex-align: center;
  width: 100%;
  backface-visibility: hidden;
  perspective: 1000px; }
  .carousel-item.active {
    display: block;
    transition: transform 0.6s ease; }

.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: transform 0.6s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none; } }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }

@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }

@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }

@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  transition-duration: 0.6s;
  transition-property: opacity;
  opacity: 0; }
  .carousel-fade .carousel-item.active {
    opacity: 1; }

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active {
  transform: translateX(0); }

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }

@supports (transform-style: preserve-3d) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active {
    transform: translate3d(0, 0, 0); }
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-prev {
    transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  width: 15%;
  text-align: center;
  opacity: 0.5;
  color: #FFF; }

.carousel-control-prev:hover, .carousel-control-prev:focus {
  text-decoration: none;
  opacity: 0.9;
  color: #FFF;
  outline: 0; }

.carousel-control-next:hover, .carousel-control-next:focus {
  text-decoration: none;
  opacity: 0.9;
  color: #FFF;
  outline: 0; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  z-index: 15;
  right: 0;
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    cursor: pointer;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ''; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ''; }
  .carousel-indicators .active {
    background-color: #FFF; }

.carousel-caption {
  position: absolute;
  z-index: 10;
  right: 15%;
  bottom: 20px;
  left: 15%;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: #FFF; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007BFF !important; }

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #0062CC !important; }

button.bg-primary:hover, button.bg-primary:focus {
  background-color: #0062CC !important; }

.bg-secondary {
  background-color: #6C757D !important; }

a.bg-secondary:hover, a.bg-secondary:focus {
  background-color: #545B62 !important; }

button.bg-secondary:hover, button.bg-secondary:focus {
  background-color: #545B62 !important; }

.bg-success {
  background-color: #28A745 !important; }

a.bg-success:hover, a.bg-success:focus {
  background-color: #1E7E34 !important; }

button.bg-success:hover, button.bg-success:focus {
  background-color: #1E7E34 !important; }

.bg-info {
  background-color: #17A2B8 !important; }

a.bg-info:hover, a.bg-info:focus {
  background-color: #117A8B !important; }

button.bg-info:hover, button.bg-info:focus {
  background-color: #117A8B !important; }

.bg-warning {
  background-color: #FFC107 !important; }

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #D39E00 !important; }

button.bg-warning:hover, button.bg-warning:focus {
  background-color: #D39E00 !important; }

.bg-danger {
  background-color: #DC3545 !important; }

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #BD2130 !important; }

button.bg-danger:hover, button.bg-danger:focus {
  background-color: #BD2130 !important; }

.bg-light {
  background-color: #F8F9FA !important; }

a.bg-light:hover, a.bg-light:focus {
  background-color: #DAE0E5 !important; }

button.bg-light:hover, button.bg-light:focus {
  background-color: #DAE0E5 !important; }

.bg-dark {
  background-color: #343A40 !important; }

a.bg-dark:hover, a.bg-dark:focus {
  background-color: #1D2124 !important; }

button.bg-dark:hover, button.bg-dark:focus {
  background-color: #1D2124 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #DEE2E6 !important; }

.border-top {
  border-top: 1px solid #DEE2E6 !important; }

.border-right {
  border-right: 1px solid #DEE2E6 !important; }

.border-bottom {
  border-bottom: 1px solid #DEE2E6 !important; }

.border-left {
  border-left: 1px solid #DEE2E6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007BFF !important; }

.border-secondary {
  border-color: #6C757D !important; }

.border-success {
  border-color: #28A745 !important; }

.border-info {
  border-color: #17A2B8 !important; }

.border-warning {
  border-color: #FFC107 !important; }

.border-danger {
  border-color: #DC3545 !important; }

.border-light {
  border-color: #F8F9FA !important; }

.border-dark {
  border-color: #343A40 !important; }

.border-white {
  border-color: #FFF !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ''; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }
.embed-responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0; }
  .embed-responsive::before {
    display: block;
    content: ''; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }
.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important;
  -ms-flex-align: start !important; }

.align-items-end {
  align-items: flex-end !important;
  -ms-flex-align: end !important; }

.align-items-center {
  align-items: center !important;
  -ms-flex-align: center !important; }

.align-items-stretch {
  align-items: stretch !important;
  -ms-flex-align: stretch !important; }

.align-content-center {
  align-content: center !important; }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-center {
    align-items: center !important;
    -ms-flex-align: center !important; } }

.float-none {
  float: none !important; }

.position-static {
  position: static !important; }

.fixed-top {
  position: fixed;
  z-index: 1030;
  top: 0;
  right: 0;
  left: 0; }

.fixed-bottom {
  position: fixed;
  z-index: 1030;
  right: 0;
  bottom: 0;
  left: 0; }

@supports (position: sticky) {
  .sticky-top {
    top: -webkit-sticky;
    right: -webkit-sticky;
    bottom: -webkit-sticky;
    left: -webkit-sticky;
    position: sticky;
    z-index: 1020;
    top: 0; } }

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  padding: 0;
  white-space: nowrap;
  border: 0; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.w-50 {
  width: 50% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-100 {
  height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.p-0 {
  padding: 0 !important; }


.px-0 {
  padding-right: 0 !important; }


.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }


.px-2 {
  padding-left: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pr-4 {
  padding-right: 1.5rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pl-4 {
  padding-left: 1.5rem !important; }


.py-5 {
  padding-top: 3rem !important; }


.py-5 {
  padding-bottom: 3rem !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 768px) {
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  
  .mx-md-auto {
    margin-left: auto !important; } }

.text-nowrap {
  white-space: nowrap !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; } }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-light {
  color: #F8F9FA !important; }

a.text-light:hover, a.text-light:focus {
  color: #DAE0E5 !important; }

.visible {
  visibility: visible !important; }

@media print {
  * {
    box-shadow: none !important;
    text-shadow: none !important; }
    *::before, *::after {
      box-shadow: none !important;
      text-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important;
    border: 1px solid #ADB5BD;
    page-break-inside: avoid; }
  blockquote {
    border: 1px solid #ADB5BD;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    width: a3;
    height: a3; }
  body,
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; } }